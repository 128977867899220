require('expose-loader?jQuery!expose-loader?$!jquery');
require('angular');
require('angular-resource');
require('angular-sanitize');
require('expose-loader?i18n!../lib/i18next/i18next-1.6.2.js');
require('expose-loader?_!lodash');

// lodash patches

// override lodash _.isFunction as it does not support async functions
// details at https://github.com/lodash/lodash/issues/2768
_.isFunction = function isFunctionOverride(value) {
	return typeof value === 'function';
};

// end of lodash patches
