import localforage from 'localforage';

import {LoggerConstructor, LoggerInstance} from '../../logger/services/logger';
import {session, Session} from './session';

const USER_PROPERTY = 'userId';

/**
 * @ngdoc service
 * @name platform.localStorage
 * @description
 *
 * Wrapper service for working with window.localStorage or IndexDB, depending on which is available, abstracts storage been actually supported or not.
 * In case storage is not supported, will always return `null` and will ignored saved data.
 * Will append application prefix to ensure no collision with other applications or browser extensions
 */
export class LocalStorageService {
	private logger: LoggerInstance;
	private localforageInstance: LocalForage;
	private inLoginPage = !this.session.user;
	private clearPromise: Promise<void>;

	//@ngInject
	constructor(Logger: LoggerConstructor, private session: Session) {
		this.logger = new Logger('localStorage');

		const name = this.getDBNameForCurrentContext();
		const storeName = this.getStoreNameForCurrentContext();
		this.localforageInstance = localforage.createInstance({name, storeName});

		this.initialize();
	}

	public initialize = _.memoize(async () => {
		await this.localforageInstance.ready();

		if (this.inLoginPage) {
			return;
		}

		await this.clearOldDb();

		await this.localforageInstance.setItem(USER_PROPERTY, this.calculateUserPropertyValue());
	});

	private async clearOldDb(): Promise<void> {
		const userId = await this.localforageInstance.getItem<string>(USER_PROPERTY);
		if (userId && userId !== this.calculateUserPropertyValue()) {
			await this.clear();
			return;
		}
	}

	private getDBNameForCurrentContext(): string {
		/* eslint-disable-next-line i18nlint/no-i18n-hardcode-string*/
		let name = 'ALM Octane'; //Term shouldn't translate

		if (session.isCustomContextPathEnabled) {
			name += ` at ${session.contextPath}`;
		}

		if (this.inLoginPage) {
			name += ' - login_page';
		} else if (session.isSiteAdminContext) {
			name += ' - site_admin';
		}

		return name;
	}

	private getStoreNameForCurrentContext(): string {
		return 'octaneLocalStorage';
	}

	private async ready(): Promise<void> {
		await Promise.all([this.initialize(), this.clearPromise]);
	}

	private calculateUserPropertyValue(): string {
		return `${this.session.user.id}:${this.session.user.uid}`;
	}

	private handleError(context: string, error) {
		if (_.isObject(error) && error.toString) {
			this.logger.warn(context, error, error.toString());
		} else {
			this.logger.warn(context, error);
		}
	}

	public async clear(): Promise<void> {
		this.clearPromise = (async () => {
			try {
				await this.localforageInstance.clear();
			} catch (e) {
				this.handleError('failed to clear storage', e);
			}
		})();

		await this.clearPromise;
	}

	public async getItem<T>(key: string): Promise<T | null> {
		try {
			await this.ready();

			return await this.localforageInstance.getItem<T>(key);
		} catch (e) {
			this.handleError(`Failed to read '${key}' from localStorage`, e);
			return null;
		}
	}

	public async getItems<T>(keyPrefix: string): Promise<T[]> {
		await this.ready();

		const keys: string[] = await this.getKeys();
		const keysToGet: string[] = keys.filter((key) => key.startsWith(keyPrefix));

		let getItemPromises: Promise<T | null>[] = keysToGet.map((key) => this.getItem(key));
		return Promise.all(getItemPromises);
	}

	public async removeItem(key: string): Promise<void> {
		try {
			await this.ready();

			await this.localforageInstance.removeItem(key);
		} catch (e) {
			this.handleError(`Failed to remove '${key}'`, e);
		}
	}

	public async removeItems(keyPrefix: string): Promise<void> {
		await this.ready();

		const keys: string[] = await this.getKeys();
		const keysToGet: string[] = keys.filter((key) => key.startsWith(keyPrefix));

		let removeItems: Promise<void>[] = keysToGet.map((key) => this.removeItem(key));
		await Promise.all(removeItems);
	}

	public async setItem(key: string, data: any): Promise<void> {
		try {
			await this.ready();

			await this.localforageInstance.setItem(key, data);
		} catch (e) {
			this.handleError(`Failed to write '${key}'`, e);
		}
	}

	private async getKeys(): Promise<string[]> {
		try {
			await this.ready();

			return await this.localforageInstance.keys();
		} catch (e) {
			this.handleError(`Failed to get localStorage keys`, e);
			return [];
		}
	}
}

angular.module('platform-session').service('localStorageService', LocalStorageService);

export let localStorageService: LocalStorageService;

angular.module('platform-session').run(function ($injector: angular.auto.IInjectorService) {
	localStorageService = $injector.get('localStorageService');
});
