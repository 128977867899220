/**
 * @ngdoc service
 * @name platform-session.session
 * @description
 *
 *  Current session information, for example
 * <pre>
 * {
 *     user: 'user_name',
 *     domain: 'DEFAULT ,
 *     project: 'SomeProject ,
 *     language: 'en',
 *     isProduction: true,
 *     apiAccessToken: 'SOME_TOKEN,
 *     logLevel: 'err'
 * }
 * </pre>
 *
 */
import '../../../globals';
import '../session-module';
import {UserAgentInfo} from '../../services/user-agent-helper';
import angular from 'angular';
import {ElasticFeature} from '../../services/elastic-under-maintenance-service';

export interface SessionUser {
	name: string;
	id: string;
	uid: string;
	type: string;
	email: string;
	groups: any[];
	fullName: string;
	phone1: string;
	language: string;
	gdprConsentRequired: boolean;
	collectDataConsentAccepted: boolean;
	dataAccessEnabled: boolean;
}

export interface EntityInfo {
	dependencies: string[];
	livesInWorkspace: boolean;
	livesInSharedSpace: boolean;
	aggregatedEntity?: string;
	name: string;
	subtype: boolean;
}

export type LicenseEdition = 'TEAM' | 'PRO' | 'ENTERPRISE' | 'VE_QUALITY' | 'VE_STRATEGY';

export interface Session {
	user: SessionUser;
	currentSharedspacePermittedWorkspaces: Map<string, string>;

	supportedLanguages: Map<string, string>;

	masterWorkspaceId: string;

	workspace: {
		id: string;
		name: string;
		logicalName: string;
		activityLevel: string;
		hasPrograms: boolean;
		planningInfoHasUDFs: boolean;
		workflowCustomization: string;
	};
	sharedSpace: {
		id: string;
		name: string;
		mode: 'ISOLATED' | 'SHARED';
		elsMaintenanceStatus: 'NOT STARTED' | 'IN PROGRESS' | 'FAILED' | 'DONE';
		syncEnabled: boolean;
		migrationVersion: string;
		metadataVersion: number;
		logicalName: string;
	};

	tenant: {
		id: number;
		type: string;
	};

	language: string;
	/**
	 * We are inside administration area, either WS / Space administration or Site Administration.
	 * Check `isSiteAdminContext` to know if we are inside site admin
	 */
	isAdministration: boolean;
	isProduction: boolean;
	isDevExperimentEnvironment: boolean;
	loadMinifiedVersions: boolean;
	apiAccessToken: string;
	logLevel: string;
	/**
	 * product version like 12.55.3
	 */
	productVersion: string;
	/**
	 * Full product version like 12.55.11.657
	 */
	currentServerVersion: string;
	productBrandDisplayName: string;
	productShortBrandDisplayName: string;
	/**
	 * Build number, in version 12.55.11.657 it would be 657
	 */
	buildVersion: string;
	uiBuildDateTime: string;
	hasSharedspaceAdminInContext: boolean;
	key: {
		modulus: string;
		exponent: string;
		prefix: string;
	};

	siteParams: {
		[index: string]: string;
	};

	isSaasEnabled: boolean;
	isAlmSharingLicense: boolean;
	isSiteAdminUser: boolean;
	isSiteAdminContext: boolean;
	isMasterWorkspaceContext: boolean;
	isLdapAuthentication: boolean;
	isSsoAuthentication: boolean;
	isTeamEdition: boolean;
	edition: LicenseEdition;
	trial: boolean;
	isDevNotMinimized: boolean;
	userHash: string;
	isSearchEnabled: string;
	isMLU: boolean;
	inUnitTests?: boolean;
	entityInfo: {
		[index: string]: EntityInfo;
	};
	collaborationTools: string[];
	cacheVersions: {[cacheType: string]: string | null};
	/**
	 * Map between bundle names and the file generated in build, in dev should be identical, in production will include hash of bundle
	 */
	buildManifest: {[bundleName: string]: string};
	userPermissions: string[];
	masterWorkspacePermissions: string[];
	hasExternalActions: boolean;
	contextPath: string;
	isCustomContextPathEnabled: boolean;
	userAgentInfo: UserAgentInfo;
	userLanguageCode?: string;
	userDataVisibilities: [];
	isEverythingVisible: boolean;
	elasticDownFeatures: ElasticFeature[];
	siteElsMaintenanceStatus: 'NOT STARTED' | 'IN PROGRESS' | 'FAILED' | 'DONE';
	collectUserAnalytics: boolean;
}

export const CLIENT_TYPE_HEADER = 'HPECLIENTTYPE';
export const CLIENT_TYPE_VALUE = 'HPE_MQM_UI';

angular.module('platform-session').constant('SESSION_TIMEOUT_MINUTES', '6');
angular.module('platform-session').constant('CLIENT_TYPE_HEADER', CLIENT_TYPE_HEADER);
angular.module('platform-session').constant('CLIENT_TYPE_VALUE', CLIENT_TYPE_VALUE);

const almSession = window['almSession'] as Session;

const sessionClone: Session = almSession.inUnitTests ? almSession : angular.copy(almSession);

angular.module('platform-session').constant('session', sessionClone);

export let session: Session = sessionClone;
