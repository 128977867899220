import 'platform/session/session-module';

import {session} from './session';

export interface Experiments {
	[experimentName: string]: boolean;
}

export const experiments: Experiments = buildExperiments();

function buildExperiments(): Experiments {
	const experiments: Experiments = window['almSession'].experiments;

	if (session.inUnitTests || !session.isDevExperimentEnvironment) {
		return experiments;
	}

	if (typeof Proxy === 'undefined') {
		return experiments;
	}

	// Do not add any experiments here, this is temp solution until below experiments are removed
	const KNOWN_MISSING_EXPERIMENTS = ['feature_planning'].sort();

	const experimentsProxy = new Proxy(experiments, {
		get(target, prop: any) {
			if (prop === 'length' || prop in target) {
				return target[prop];
			}

			if (KNOWN_MISSING_EXPERIMENTS.includes(prop)) {
				return false;
			}

			throw new Error(`Unknown experiment: ${prop}`);
		},
	});

	return experimentsProxy;
}

(function () {
	var sessionModule = angular.module('platform-session');

	/**
	 * @ngdoc service
	 * @name platform-session.experiments
	 * @description
	 *
	 *  Current map of experiments in site for current session, looks like
	 * <pre>
	 * {
	 *     foo: true,
	 *     bar: false
	 * }
	 * </pre>
	 */
	sessionModule.constant('experiments', experiments);
})();
