import {IQService, IRootScopeService} from 'angular';
import {Session} from './session';
import {awaitAdapter, initAwaitAdapter} from 'platform/session/services/await-adapter';
import {experiments} from './experiments';

angular.module('platform-session').run(bridgeBetweenNativePromiseAndAngularDigestCycle);

function bridgeBetweenNativePromiseAndAngularDigestCycle(
	$q: IQService,
	$rootScope: IRootScopeService,
	session: Session
) {
	'ngInject';
	const originalPromise = window.Promise;
	const originalThen = originalPromise.prototype.then;

	initAwaitAdapter({
		$rootScope,
		inUnitTests: session.inUnitTests,
		digestWait: Number(session.siteParams.DIGEST_DEBOUNCE),
		digestMaxWait: Number(session.siteParams.DIGEST_MAX_DEBOUNCE),
	});

	patchNativePromiseWithInterceptor();

	if (experiments && !experiments.async_await_stop_override_promise) {
		overridePromiseInES5();
	}

	return;

	function overridePromiseInES5() {
		/*
			We are overriding native implementation of Promise with angular $q which is api compatible to Promise.
			We do this to hook up all code using Promise into AngularJS digest cycle.
			If this code is disabled, all places using Promise will resolve outside of AngularJS digest cycle which breaks
			application as it changes timing of component rendering and some times blocks all together until user interaction
			which will trigger next digest cycle.

			!!! This code must not be removed !!!
			until we solve all those places that depend on this behavior,
			which at the moment we have no good way to map out.
		 */
		window.Promise = $q as any;

		$rootScope.$on('$destroy', () => {
			window.Promise = originalPromise;
		});
	}

	/**
	 * Intercept native promise to trigger angular digest cycle
	 */
	function patchNativePromiseWithInterceptor() {
		originalPromise.prototype.then = function octanePromiseThenInterceptor() {
			const promise = originalThen.apply(this, arguments);

			originalThen.call(promise, awaitAdapter, awaitAdapter);

			return promise;
		};

		$rootScope.$on('$destroy', () => {
			originalPromise.prototype.then = originalThen;
		});
	}
}
